import React, { useEffect, useState, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { HelmetProvider } from 'react-helmet-async';

const HomePage = lazy(() => import('./Components/HomePage'));
const TermsOfService = lazy(() => import('./Components/Pages/TermsOfService'));
const PrivacyPolicy = lazy(() => import('./Components/Pages/PrivacyPolicy'));
const AboutUs = lazy(() => import('./Components/Pages/AboutUs'));
const FAQ = lazy(() => import('./Components/Pages/FAQ'));
const Contact = lazy(() => import('./Components/Pages/Contact'));
const Blog = lazy(() => import('./Components/Pages/Blog'));
const BlogPost = lazy(() => import('./Components/Pages/BlogPost'));
const Exercise = lazy(() => import('./Components/Pages/Exercise'));

// import { requestForToken, onMessageListener } from './firebaseConfig';
// import GlobalStyles from './GlobalStyles';
// import { ThemeProvider } from 'styled-components';
// import { theme } from './theme';

function App() {
  const [isTokenFound, setTokenFound] = useState(false);
  const [token, setToken] = useState('');
  const [darkMode, setDarkMode] = useState(() => {
    // Check local storage for the current mode
    const savedMode = localStorage.getItem('darkMode');
    return savedMode ? JSON.parse(savedMode) : false;
  });

  const [userSettings, setUserSettings] = useState(() => {
    // Check local storage for the current mode
    const userSettings = localStorage.getItem('userSettings');
    return userSettings ? JSON.parse(userSettings) : false;
  });

  const setFavicon = (iconUrl) => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = iconUrl;
  };

  useEffect(() => {
  // Retrieve the localStorage variable
  // Determine which favicon to set
  if (userSettings.teacher === 'susie') {
    setFavicon('/susie.ico'); // Replace with your actual path
  } else if (userSettings.teacher === 'harvey') {
    setFavicon('/harvey.ico'); // Replace with your actual path
  } else {
    setFavicon('/susie.ico'); // Default favicon
  }

}, []);



  useEffect(() => {
    /*
    const body = document.body;
    if (darkMode) {
      body.classList.remove('light-mode');
      body.classList.add('dark-mode');
    } else {
      body.classList.remove('dark-mode');
      body.classList.add('light-mode');
    }
    */
    // Request permission and get the token for notifications
    /*
    requestForToken((tokenFound) => {
      setTokenFound(tokenFound);
      console.log(tokenFound)
      if (tokenFound) {
        setToken(tokenFound);
        console.log(tokenFound)
      }
    });
    */

    // Set up listener for foreground push notifications
    /*
    onMessageListener()
      .then((payload) => {
        console.log('Notification received: ', payload);
        toast(payload.notification.title);
      })
      .catch((err) => console.log('Failed to receive notification: ', err));
      */
  }, []);

  /*
  const sendTestNotification = () => {
    if (token) {
      fetch(`https://us-central1-eurekai-1234.cloudfunctions.net/sendTestNotification?token=${token}`)
        .then(response => response.text())
        .then(data => {
          console.log(data);
          toast('Test notification sent');
        })
        .catch((error) => {
          console.error('Error sending notification:', error);
          toast('Error sending notification');
        });
    } else {
      toast('No token found');
    }
  };
  */
  //         <button onClick={sendTestNotification}>Send Test Notification</button>

  return (
    <HelmetProvider>

    <Router>
      <div className="App">
      <Suspense>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/TermsOfService" element={<TermsOfService />} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/AboutUs" element={<AboutUs />} />
          <Route path="/FAQ" element={<FAQ />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Exercise" element={<Exercise />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:slug" element={<BlogPost />} /> {/* Dynamic route for individual posts */}

        </Routes>
        </Suspense>
        <ToastContainer />
      </div>
    </Router>
    </HelmetProvider>

  );
}

export default App;


/*

<ThemeProvider theme={theme}>
<GlobalStyles />
... the rest
</ThemeProvider>
*/
